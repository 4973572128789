@use "/src/utils/colors";

html,
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  background-color: white;
}

.xsmall-text {
  font-size: 0.6em !important;
}

.msmall-text {
  font-size: 0.8em !important;
}

.small-text {
  font-size: 0.9em !important;
}

.medium-text {
  font-size: 1em !important;
}

.large-text {
  font-size: 1.2em !important;
}

.bold-text {
  font-weight: bold !important;
}

.blue-text {
  color: colors.$blue5 !important;
}

.help-text {
  font-style: italic;
  font-size: 1em;
}

.ml-10 {
  margin-left: 10px;
}

input,
button {
  font-size: 1em;
}

svg {
  font-size: 1.8em;
}

.MuiButton-root {
  font-weight: 600 !important;
}

.MuiCircularProgress-colorPrimary {
  color: colors.$blue4 !important;
}

#app-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  #app-inner-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: auto;
    position: relative;
    // padding-right: 4%;
    // padding-left: 30px;
    // padding-top: 2%;
    overflow-x: hidden;

    .page-header {
      color: #5588a3;
      // text-transform: uppercase;
      line-height: 35px;
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.34px;
      padding-left: 10px;
    }

    .page-header-wrapper {
      background-color: rgba(204, 219, 227, 0.2);
      padding: 10px;
      border-radius: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &.fullscreen {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 2000;
    }

    @media (max-width: 600px) {
      overflow-x: scroll;
    }
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.dialog-title {
  font-family: Open Sans, sans-serif;
  font-weight: 500 !important;
  line-height: 2.5;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding: 10px 30px 0 30px;
  color: colors.$blue5;

  p {
    text-transform: none;
    line-height: 1;
    font-weight: normal !important;
  }
}

.warning {
  color: #a04b5a;
}

.sep {
  color: colors.$grey5;
  margin-top: 30px;
}

.dialog-content {
  font-family: Rubik;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #00334e;
  padding: 0 30px;

  .export-option {
    font-family: Rubik;
    font-weight: 500;
    line-height: 2.92;
    letter-spacing: 0.6px;
    text-align: left;
    color: colors.$blue5;
    margin-right: 30px;
  }

  .inner-container {
    padding: 20px 0;
    min-height: 90px;

    .label {
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 5px;

      span {
        font-weight: normal;
        text-transform: none;
        margin-left: 10px;
      }
    }

    .select-xlarge {
      width: 410px;
    }

    input,
    input:focus {
      border: none;
      //height: 28px;
      outline: none;
      color: colors.$blue5;
      //margin-right: 5px;
      width: 95%;
      padding-left: 10px;
      font-family: Rubik;
      border-radius: 5px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
      background-color: rgba(85, 136, 163, 0.2);
      height: 35px;
      width: 400px;
    }

    .small {
      font-size: 0.8em;
      color: colors.$blue4;
      padding-top: 10px;
      padding-left: 5px;
    }

    .small-error {
      font-size: 0.8em;
      color: #a04b5a;
      padding-top: 10px;
      padding-left: 5px;
    }
  }
}

.dialog-footer {
  padding: 0 30px 20px 30px;
  text-align: right;

  hr {
    width: 100%;
    margin-bottom: 20px;
    background-color: colors.$grey5;
    height: 2px;
    border: none;
  }

  .action {
    font-family: Rubik;
    letter-spacing: 1.2px;
    text-align: left;
    color: colors.$blue4 !important;
    border-radius: 100px !important;
    border: solid 2px colors.$blue4 !important;
    padding: 5px 15px 5px 15px;
    font-weight: 400 !important;
    min-width: 120px;
    min-height: 40px;
    &:hover {
      transform: scale(1.05);
      background-color: rgba(85, 136, 163, 0.1);
    }
    &:disabled {
      cursor: not-allowed !important;
      color: #959494 !important;
      border-color: #959494 !important;
    }

    svg {
      margin-right: 3px;
    }

    span {
      display: flex;
      svg {
        margin-right: 3px;
        margin-top: 3px;
      }
    }
  }

  .page-counter {
    float: left;
    margin-top: 10px;
    span {
      color: colors.$blue4;
    }
  }

  .cancel {
    font-family: Rubik;
    letter-spacing: 1.2px;
    text-align: left;
    color: #959494 !important;
    margin-right: 15px !important;
    font-weight: 400 !important;
    &:hover {
      transform: scale(1.05);
      background-color: #fff;
    }

    svg {
      margin-right: 3px;
      margin-top: -2px;
    }
  }

  .delete {
    border: 1px solid #9a6060;
    //border: none;
    color: #9a6060;
    background-color: white;
    margin-right: 15px;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 5px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    padding: 5px 15px 5px 15px;
    font-weight: 400 !important;
    min-width: 120px;
    min-height: 40px;

    svg {
      font-size: 20px;
    }
    div {
      font-family: Rubik;
      font-weight: 500;
      margin-top: 4px;
    }

    &:disabled {
      border: 1px solid colors.$grey5;
      background-color: colors.$grey5;
      cursor: not-allowed;
    }

    &:hover {
      transform: scale(1.1);
      background-color: #a04b5b2b;
    }
  }
}

.error {
  font-family: 'Open Sans';
  color: red;
  padding-top: 12px;
}

.MuiPaper-root {
  scrollbar-color: colors.$blue4 rgba(149, 148, 148, 0.3);
  scrollbar-width: thin;
}

.MuiTableContainer-root {
  scrollbar-color: colors.$blue4 #fafafa;
  scrollbar-width: thin;
  position: relative;
  z-index: 0;
  padding-bottom: 0px !important;
  max-height: 65vh;

  table {
    border-spacing: 0px 5px;
    padding-right: 10px !important;

    thead {
      tr {
        padding-top: 0px !important;
        padding-bottom: 0px !important;

        th {
          .sortable {
            cursor: pointer;
            padding: 0px;
            align-self: baseline;

            svg {
              font-size: 20px;
              color: #a19090;
              margin: 0px;
              margin-left: 5px;
              margin-bottom: -5px;
            }

            svg.selected {
              color: colors.$blue5;
            }

            svg.reverse {
              transform: scale(1, -1);
            }
          }

          .header-icon {
            display: flex;
            justify-content: center;
            svg {
              margin-bottom: -5px;
            }
          }

          svg {
            cursor: pointer;
          }
        }
      }
    }

   
  }

  .collapse-table {
    th {
      background-color: #fff !important;
    }

    .MuiTableCell-root {
      background-color: transparent;
    }

    tr {
      table {
        tr:not(.inactive) {
          .MuiTableCell-root {
            background: rgb(233, 245, 255);
          }
        }
        tr.inactive {
          .MuiTableCell-root {
            background: rgb(234, 234, 234) !important;
          }
          .actions {
            background: rgb(234, 234, 234) !important;
          }
        }
      }
    }

    .collapse-row {
      position: relative;
      display: block;
      background: rgb(233, 245, 255);
      padding: 1%;
      border-top: 1px solid #fafafa;
      border-radius: 0px 0px 10px 10px;
      width: 98% !important;
    }

    .subscription-component-params {
      display: flex;
      line-height: 1.2;

      .filters-container {
        width: 50%;
        word-break: break-word;
        padding-right: 10px;
      }
    }

    .collapse-row-inactive {
      background: rgb(234, 234, 234);
      color: #7e7e7e !important;
      position: relative;
      padding: 1%;
      border-top: 1px solid #fafafa;
      border-radius: 0px 0px 10px 10px;
      width: 98% !important;
    }
  }
}

.MuiTableCell-head {
  font-weight: bold !important;
  vertical-align: bottom !important;
  justify-items: baseline !important;
}

.MuiTableCell-root {
  font-family: Open Sans,sans-serif !important;
  font-stretch: normal !important;
  font-style: normal !important ;
  letter-spacing: 0.6px !important;
  color: colors.$blue5 !important;
  border-bottom: none !important;
  // background: rgb(233, 245, 255);
  line-height: 1 !important;

  a {
    color: #00aaa9;
    &:hover {
      opacity: 0.8;
    }
    &.disabled {
      cursor: text !important;
      text-decoration: none;
      svg {
        color: colors.$grey3 !important;
      }
    }
  }

  button {
    background: unset;
    border-image: unset;
    border-style: unset;
    border-width: unset;
    transition: transform 100ms ease-in-out;
    font-family: Rubik;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.39px;
    color: #00aaa9;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      opacity: 0.9;
      //transform: scale(1.1);
    }

    &:active {
      cursor: pointer;
      //transform: scale(0.9);
    }

    &:disabled {
      cursor: text;
      color: colors.$blue5;
      text-decoration: none;
    }
  }

  .show {
    cursor: pointer;
    margin-left: 1%;
    div {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-weight: 500;
        width: 40px;
      }

      svg {
        margin: 0px !important;
        padding: 0 !important;
        line-height: 1 !important;
      }
    }
  }
}

.MuiCollapse-root {
  width: 98.5%;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border: solid 1px rgba(149, 148, 148, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  border-radius: 10px;
}

.Mui-checked svg {
  color: #165CE8 !important;
}

a:hover {
  background-color: transparent !important;
}
.primary-btn{
  button{
    border-radius: 4px;
    border: 1px solid #2C4259;
    background: var(--main-primary-03, #2C4259);
    display: inline-flex;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    color: #FFF;
    font-family: Open Sans,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 14px;
    letter-spacing: -0.32px;
    &:hover{
      border-radius: 4px;
      border: 1px solid var(--csk-142330700, #2D4F6C);
      background: var(--csk-142330700, #2D4F6C);
    }
  }
}
.main-box{
  // padding-top: 5px;
  @media (max-width: 600px) {
    padding-bottom: 10%;
    height: 93% !important;
  }
}